/* eslint-disable max-lines */
const { getBranchAwareEnv } = require('./getBranchAwareEnv');

type STRIPE = {
  DE_PUBLISHABLE_KEY: string;
  ABS_DE_PUBLISHABLE_KEY: string;
  USA_PUBLISHABLE_KEY: string;
  E2E_DE_PUBLISHABLE_KEY: string;
  E2E_ABS_DE_PUBLISHABLE_KEY: string;
  E2E_USA_PUBLISHABLE_KEY: string;
};

type LEVER = {
  API_URL: string;
  ACCESS_TOKEN: string;
};

type Cloudinary = {
  REMOTE_IMAGE_FETCH_URL: string;
};

interface IEnvironment {
  DATADOG_APPLICATION_ID?: string;
  DATADOG_CLIENT_TOKEN?: string;
  DATADOG_RUM_SESSION_SAMPLE_RATE?: string;
  DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE?: string;
  DD_ENV?: string;
  DD_VERSION?: string;
  PRODUCT_API_URL: string;
  DEPLOY_URL: string;
  FINN_WEB_URL: string;
  APP_STAGE: string;
  COSMIC_BUCKET_READ_KEY: string;
  HANDOVER_API_URL: string;
  FLEET_API_URL: string;
  TAX_API_URL: string;
  TAX_API_KEY: string;
  VOUCHER_API_URL: string;
  CHECKOUT_API_URL: string;
  AUTH_API_URL: string;
  SUPPORT_EMAIL: string;
  CLOUDINARY: Cloudinary;
  INTEGROMAT_CREATE_DEAL_URL: string;
  INTEGROMAT_B2B_FORM_SUBMISSION_URL_DE: string;
  INTEGROMAT_B2B_FORM_SUBMISSION_URL_US: string;
  INTEGROMAT_B2C_FORM_SUBMISSION_URL: string;
  INTEGROMAT_REFERRAL_VOUCHER_CODE: string;
  INTEGROMAT_RETENTION_VOUCHER_CODE: string;
  LEVER: LEVER;
  USERCENTRICS_ID: string;
  COSMIC_USE_S3: string;
  TRACKING_TEST_EMAIL: string;
  SELF_SERVICE_API_URL: string;
  REVIEWS_API_URL: string;
  CUSTOMER_SELF_SERVICE_API_URL: string;
  CUSTOMER_PORTAL_URL: string;
  APP_PREFIX: string;
  FINN_AUTO_URL: string;
  UA_FE_ACTOR: string;
  UA_FE_SSR_ACTOR: string;
  APP_PREVIEW_PREFIX: string;
  ANALYTICS_WRITE_KEY: string;
  STRIPE: STRIPE;
  STRIPE_CUSTOMER_PORTAL_US_URL: string;
  INTEGROMAT_JOBAUTO_FORM: string;
  INTEGROMAT_PARTNERSHIPS_FORM: string;
  INTEGROMAT_JOBAUTO_WHITEPAPER_FORM: string;
  GMC_TAG: string;
  REVALIDATION_DEFAULT_TIMEOUT: number;
  REVALIDATION_SHORT_TIMEOUT: number;
  REVALIDATION_LONG_TIMEOUT: number;
  GOOGLE_MAPS_GEOLOCATION_URL: string;
  GOOGLE_MAPS_PLACES_API_DOMAIN_RESTRICTED: string;
  GOOGLE_MAPS_SERVER_API_KEY: string;
  EXTERNAL_VOUCHERS_API_KEY: string;
  GOOGLE_ANALYTICS_4_WEB: string;
  APPS_FLYER_SMART_BANNER_KEY: string;
  FINN_APP_SCHEME: string;
  SUBSCRIPTION_API_URL: string;
  HUBSPOT_API_URL: string;
  HUBSPOT_API_KEY: string;
  FINN_MAIN_DOMAIN: string;
  RECAPTCHA_E2E_SECRET?: string;
  ZENDESK_JWT_SECRET: string;
  ZENDESK_APP_ID: string;
  DECAGON_API_KEY: string;
  B2B_API_ENDPOINTS: {
    ADDONS: string;
    CHECKOUT_V0: string;
    CHECKOUT_V1: string;
    SUBSCRIPTIONS: string;
    COMPANIES: string;
    ORDERS: string;
    PRODUCT: string;
    DELIVERY: string;
    LEADS: string;
    INVOICES: string;
    FINANCE_BILLING: string;
    FINANCE_INVOICING: string;
    OPS_CARS: string;
    OPS_SUBSCRIPTIONS: string;
    OPS_APPRAISALS: string;
  };
}

// these are publishable/public keys, so it's not a security issue to have it in source code.
// Having them in env variable is problematic as we're reaching the 4kb env variable limit
const StripeAllPublishableKeys = {
  test: {
    de: 'pk_test_SbCnSrPH0r7zEyklLZTv5AAD00pI5fqzsQ',
    abs_de:
      'pk_test_51JrkEqHfRoBp5g8KMn3UrQV8Y2PZMRisN3eEvbF9Uroyz35pwiwyk4NB3QIaAMiBL2aXZv02qJ9riRq3RqlmPqSN00JCjBhRER',
    usa: 'pk_test_51JJwEKKQiXYhvDwQEqBhmHUMPsbGkNq3z4LhosGbsgCPTXBcH63HX6JxL4W1EvDgUEFR7YCHEZTQ2ObGa1SaoIJN00pyzdnWXz',
  },
  live: {
    de: 'pk_live_65j0ShiPOlhoPtEf8Bf7TztH00psbnKRMs',
    abs_de:
      'pk_live_51JrkEqHfRoBp5g8KJ8oXnGXkYZc4qIU6iHjOXwMyfaTMXVW2tueDk9htEiBoumPVXWbfID1MFdA51jDVtOkp85RE00rEK5Eo87',
    usa: 'pk_live_51JJwEKKQiXYhvDwQaA4Jq0IaqSyrdaEQ3Eu0MPafDee1HjVpZkmA6Y8btu2MbYXmnXKZfqycroFVyI5aKp5QVpZ500QgHpFg7b',
  },
};

const StripeTestKeySet: STRIPE = {
  DE_PUBLISHABLE_KEY: StripeAllPublishableKeys.test.de,
  ABS_DE_PUBLISHABLE_KEY: StripeAllPublishableKeys.test.abs_de,
  USA_PUBLISHABLE_KEY: StripeAllPublishableKeys.test.usa,
  E2E_DE_PUBLISHABLE_KEY: StripeAllPublishableKeys.test.de,
  E2E_ABS_DE_PUBLISHABLE_KEY: StripeAllPublishableKeys.test.abs_de,
  E2E_USA_PUBLISHABLE_KEY: StripeAllPublishableKeys.test.usa,
};

const StripeLiveKeySet: STRIPE = {
  DE_PUBLISHABLE_KEY: StripeAllPublishableKeys.live.de,
  ABS_DE_PUBLISHABLE_KEY: StripeAllPublishableKeys.live.abs_de,
  USA_PUBLISHABLE_KEY: StripeAllPublishableKeys.live.usa,
  E2E_DE_PUBLISHABLE_KEY: StripeAllPublishableKeys.test.de,
  E2E_ABS_DE_PUBLISHABLE_KEY: StripeAllPublishableKeys.test.abs_de,
  E2E_USA_PUBLISHABLE_KEY: StripeAllPublishableKeys.test.usa,
};

const development: IEnvironment = {
  DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN,
  DATADOG_APPLICATION_ID: process.env.DATADOG_APPLICATION_ID,
  DATADOG_RUM_SESSION_SAMPLE_RATE:
    process.env.DATADOG_RUM_SESSION_SAMPLE_RATE || '0',
  DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE:
    process.env.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE || '0',
  DD_ENV: process.env.DD_ENV || 'development',
  PRODUCT_API_URL: process.env.PRODUCT_API_URL || '',
  GOOGLE_ANALYTICS_4_WEB: 'G-K7KRXNGPYV',
  DEPLOY_URL: process.env.NEXT_PUBLIC_DEPLOY_URL ?? 'http://localhost:3000',
  FINN_WEB_URL: 'https://www.finn.com',
  SUBSCRIPTION_API_URL:
    process.env.SUBSCRIPTION_API_URL ||
    'https://stg-api-ops-subscriptions.finn.auto',
  HUBSPOT_API_URL:
    process.env.HUBSPOT_API_URL || 'https://api.hubapi.com/crm/v3/objects',
  HUBSPOT_API_KEY: process.env.HUBSPOT_API_KEY || '',
  APP_STAGE: process.env.APP_STAGE || 'development',
  COSMIC_BUCKET_READ_KEY: process.env.COSMIC_BUCKET_READ_KEY || '',
  SUPPORT_EMAIL: 'support@finn.auto',
  HANDOVER_API_URL:
    getBranchAwareEnv('HANDOVER_API_URL') ||
    process.env.HANDOVER_API_URL ||
    'https://dev-api-car-handover.finn.auto',
  FLEET_API_URL:
    getBranchAwareEnv('FLEET_API_URL') || process.env.FLEET_API_URL || '',
  TAX_API_URL: process.env.TAX_API_URL || '',
  TAX_API_KEY: process.env.TAX_API_KEY || '',
  VOUCHER_API_URL:
    getBranchAwareEnv('VOUCHER_API_URL') ||
    process.env.VOUCHER_API_URL ||
    'https://dev-api-voucher.finn.auto',
  CHECKOUT_API_URL:
    getBranchAwareEnv('CHECKOUT_API_URL') || process.env.CHECKOUT_API_URL || '',
  AUTH_API_URL:
    getBranchAwareEnv('AUTH_API_URL') || process.env.AUTH_API_URL || '',
  CLOUDINARY: {
    REMOTE_IMAGE_FETCH_URL: 'https://res.cloudinary.com/finn-auto/image/fetch/',
  },
  INTEGROMAT_CREATE_DEAL_URL:
    'https://hook.integromat.com/5f16mjfvbpy8d932knqyff88ji2duluz',
  INTEGROMAT_B2B_FORM_SUBMISSION_URL_DE:
    'https://hook.finn.integromat.cloud/2bs397kd9tqqq14k36cjw2mkxpiht4zg',
  INTEGROMAT_B2B_FORM_SUBMISSION_URL_US:
    'https://hook.finn.integromat.cloud/c9x5yv4lamrfdmfpx8ygfkfeonmgvqm8',
  INTEGROMAT_B2C_FORM_SUBMISSION_URL:
    process.env.INTEGROMAT_B2C_FORM_SUBMISSION_URL || '',
  INTEGROMAT_REFERRAL_VOUCHER_CODE:
    'https://hook.finn.integromat.cloud/d5qt21phj3zhg83bfr1a2e9gbehmxqzr',
  INTEGROMAT_RETENTION_VOUCHER_CODE:
    'https://hook.finn.integromat.cloud/d6cesdritxqlejdweo2w54sad8jfvhll',
  INTEGROMAT_JOBAUTO_FORM:
    'https://hook.finn.integromat.cloud/hwajx3lsy9qq30c0katp150g5g1sy0w9',
  INTEGROMAT_PARTNERSHIPS_FORM:
    'https://hook.finn.integromat.cloud/56gh7f6ldsy7ng77dtm3o5emvtfta135',
  INTEGROMAT_JOBAUTO_WHITEPAPER_FORM:
    'https://hook.finn.integromat.cloud/s5b7qbrkqjbruf6erjcxxvoyc5hhw7w7',
  LEVER: {
    API_URL: 'https://api.lever.co/v1',
    ACCESS_TOKEN: process.env.LEVER_ACCESS_TOKEN || '',
  },
  USERCENTRICS_ID: process.env.NEXT_PUBLIC_USERCENTRICS_ID || '',
  COSMIC_USE_S3: process.env.NEXT_PUBLIC_COSMIC_USE_S3 || '',
  TRACKING_TEST_EMAIL: process.env.NEXT_PUBLIC_TRACKING_TEST_EMAIL || '',
  SELF_SERVICE_API_URL: 'https://dev-service-self.finn.auto',
  REVIEWS_API_URL: process.env.REVIEWS_API_URL || '',
  CUSTOMER_SELF_SERVICE_API_URL:
    getBranchAwareEnv('CUSTOMER_SELF_SERVICE_API_URL') ||
    process.env.CUSTOMER_SELF_SERVICE_API_URL ||
    'https://dev-customer-self-service.finn.auto',
  APP_PREFIX: process.env.APP_PREFIX || 'finnautoapp://',
  APP_PREVIEW_PREFIX: process.env.APP_PREVIEW_PREFIX || 'finnautoapppreview://',
  // ANALYTICS_WRITE_KEY will have different values between auto-ui and b2b-ui apps, as event tracking is separated
  // Default value is set to auto-ui key:
  ANALYTICS_WRITE_KEY:
    process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY ||
    'tQkptwFW7tocJCghWSiFGJarHixkbTHg',
  STRIPE: StripeTestKeySet,
  STRIPE_CUSTOMER_PORTAL_US_URL:
    process.env.STRIPE_CUSTOMER_PORTAL_US_URL ||
    'https://payments-us.finn.auto/p/login/test_cN2cNf23qgf86zK5kk',
  GMC_TAG: 'Xa9ZgPjLkRZp7GaPTMDzzki733VjXQ0eg8A2a51wJOQ',
  REVALIDATION_DEFAULT_TIMEOUT: 5 * 60,
  REVALIDATION_SHORT_TIMEOUT: 120,
  REVALIDATION_LONG_TIMEOUT: 15 * 60,
  GOOGLE_MAPS_GEOLOCATION_URL:
    'https://www.googleapis.com/geolocation/v1/geolocate',
  GOOGLE_MAPS_PLACES_API_DOMAIN_RESTRICTED:
    'AIzaSyDOkK0DSERRufvtGF5qzZDlGE-zLN0f2_E',
  GOOGLE_MAPS_SERVER_API_KEY: process.env.GOOGLE_MAPS_SERVER_API_KEY || '',
  CUSTOMER_PORTAL_URL:
    getBranchAwareEnv('CUSTOMER_PORTAL_URL') ||
    process.env.CP_DEPLOY_PREVIEW_URL ||
    process.env.CUSTOMER_PORTAL_URL ||
    '',
  FINN_AUTO_URL: process.env.FINN_AUTO_URL || '',
  UA_FE_ACTOR: process.env.UA_FE_ACTOR || 'ua_frontend',
  UA_FE_SSR_ACTOR: process.env.UA_FE_SSR_ACTOR || 'ua_frontend_ssr',
  FINN_MAIN_DOMAIN: process.env.FINN_MAIN_DOMAIN || 'https://www.finn.com',
  EXTERNAL_VOUCHERS_API_KEY: process.env.EXTERNAL_VOUCHERS_API_KEY || '',
  APPS_FLYER_SMART_BANNER_KEY:
    process.env.APPS_FLYER_SMART_BANNER_KEY ||
    '75007f8c-c424-4fa5-bedb-97b945f34805',
  FINN_APP_SCHEME: process.env.FINN_APP_SCHEME || 'finnautoapp://',
  RECAPTCHA_E2E_SECRET: process.env.RECAPTCHA_E2E_SECRET,
  ZENDESK_JWT_SECRET: process.env.ZENDESK_JWT_SECRET || '',
  ZENDESK_APP_ID: process.env.ZENDESK_APP_ID || '',
  DECAGON_API_KEY: process.env.DECAGON_API_KEY || '',
  B2B_API_ENDPOINTS: {
    ADDONS: 'https://addons-api.b2b-staging.finn.com',
    CHECKOUT_V0: 'https://orders-api.b2b-staging.finn.com/v0',
    CHECKOUT_V1: 'https://orders-api.b2b-staging.finn.com/v1',
    SUBSCRIPTIONS: 'https://stg-b2b-subscriptions-api.finn.auto/v1',
    COMPANIES: 'https://companies.de.b2b.finn.com/api/v0',
    ORDERS: 'https://orders-api.b2b-staging.finn.com',
    PRODUCT: 'https://staging-product-api.finn.com/b2b',
    DELIVERY: 'https://api-delivery.finn.auto/v1',
    LEADS: 'https://api-lead.finn.auto/v0',
    INVOICES: 'https://invoice-b2b.finn.auto/v1',
    FINANCE_BILLING: 'https://api-billing.finance.finn.auto',
    FINANCE_INVOICING: 'https://api-invoicing.finance.finn.auto',
    OPS_CARS: 'https://api-ops-cars.finn.auto',
    OPS_SUBSCRIPTIONS: 'https://api-ops-subscriptions.finn.auto',
    OPS_APPRAISALS: 'https://api-ops-appraisals.finn.auto',
  },
};

const staging: IEnvironment = {
  PRODUCT_API_URL: process.env.PRODUCT_API_URL || '',
  DATADOG_CLIENT_TOKEN:
    process.env.DATADOG_CLIENT_TOKEN || 'pub3ba7a11ecc7e9f18f2ef5dd41364a444',
  DATADOG_APPLICATION_ID:
    process.env.DATADOG_APPLICATION_ID ||
    'a3422b68-51d0-4ec3-a45c-2bb483409d25',
  DATADOG_RUM_SESSION_SAMPLE_RATE:
    process.env.DATADOG_RUM_SESSION_SAMPLE_RATE || '100',
  DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE:
    process.env.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE || '100',
  DD_ENV: process.env.DD_ENV || 'staging',
  DD_VERSION: process.env.COMMIT_REF,
  GOOGLE_ANALYTICS_4_WEB: 'G-K7KRXNGPYV',
  DEPLOY_URL: process.env.NEXT_PUBLIC_DEPLOY_URL ?? 'https://www.finn.com',
  FINN_WEB_URL: 'https://www.finn.com',
  SUBSCRIPTION_API_URL:
    process.env.SUBSCRIPTION_API_URL ||
    'https://stg-api-ops-subscriptions.finn.auto',
  HUBSPOT_API_URL:
    process.env.HUBSPOT_API_URL || 'https://api.hubapi.com/crm/v3/objects',
  HUBSPOT_API_KEY: process.env.HUBSPOT_API_KEY || '',
  APP_STAGE: process.env.APP_STAGE || 'preview',
  COSMIC_BUCKET_READ_KEY: process.env.COSMIC_BUCKET_READ_KEY || '',
  SUPPORT_EMAIL: 'support@finn.auto',
  HANDOVER_API_URL:
    getBranchAwareEnv('HANDOVER_API_URL') ||
    process.env.HANDOVER_API_URL ||
    'https://api-car-handover.finn.auto',
  FLEET_API_URL:
    getBranchAwareEnv('FLEET_API_URL') || process.env.FLEET_API_URL || '',
  TAX_API_URL: process.env.TAX_API_URL || '',
  TAX_API_KEY: process.env.TAX_API_KEY || '',
  VOUCHER_API_URL:
    getBranchAwareEnv('VOUCHER_API_URL') ||
    process.env.VOUCHER_API_URL ||
    'https://api-voucher.finn.auto',
  CHECKOUT_API_URL:
    getBranchAwareEnv('CHECKOUT_API_URL') || process.env.CHECKOUT_API_URL || '',
  AUTH_API_URL:
    getBranchAwareEnv('AUTH_API_URL') || process.env.AUTH_API_URL || '',
  CLOUDINARY: {
    REMOTE_IMAGE_FETCH_URL: 'https://res.cloudinary.com/finn-auto/image/fetch/',
  },
  INTEGROMAT_CREATE_DEAL_URL:
    'https://hook.integromat.com/5f16mjfvbpy8d932knqyff88ji2duluz',
  INTEGROMAT_B2B_FORM_SUBMISSION_URL_DE:
    'https://hook.finn.integromat.cloud/2bs397kd9tqqq14k36cjw2mkxpiht4zg',
  INTEGROMAT_B2B_FORM_SUBMISSION_URL_US:
    'https://hook.finn.integromat.cloud/c9x5yv4lamrfdmfpx8ygfkfeonmgvqm8',
  INTEGROMAT_B2C_FORM_SUBMISSION_URL:
    process.env.INTEGROMAT_B2C_FORM_SUBMISSION_URL || '',
  INTEGROMAT_REFERRAL_VOUCHER_CODE:
    'https://hook.finn.integromat.cloud/d5qt21phj3zhg83bfr1a2e9gbehmxqzr',
  INTEGROMAT_RETENTION_VOUCHER_CODE:
    'https://hook.finn.integromat.cloud/d6cesdritxqlejdweo2w54sad8jfvhll',
  INTEGROMAT_JOBAUTO_FORM:
    'https://hook.finn.integromat.cloud/hwajx3lsy9qq30c0katp150g5g1sy0w9',
  INTEGROMAT_PARTNERSHIPS_FORM:
    'https://hook.finn.integromat.cloud/56gh7f6ldsy7ng77dtm3o5emvtfta135',
  INTEGROMAT_JOBAUTO_WHITEPAPER_FORM:
    'https://hook.finn.integromat.cloud/s5b7qbrkqjbruf6erjcxxvoyc5hhw7w7',
  LEVER: {
    API_URL: 'https://api.lever.co/v1',
    ACCESS_TOKEN: process.env.LEVER_ACCESS_TOKEN || '',
  },
  USERCENTRICS_ID: process.env.NEXT_PUBLIC_USERCENTRICS_ID || '',
  COSMIC_USE_S3: process.env.NEXT_PUBLIC_COSMIC_USE_S3 || '',
  TRACKING_TEST_EMAIL: process.env.NEXT_PUBLIC_TRACKING_TEST_EMAIL || '',
  SELF_SERVICE_API_URL: 'https://stg-service-self.finn.auto',
  REVIEWS_API_URL: process.env.REVIEWS_API_URL || '',
  CUSTOMER_SELF_SERVICE_API_URL:
    getBranchAwareEnv('CUSTOMER_SELF_SERVICE_API_URL') ||
    process.env.CUSTOMER_SELF_SERVICE_API_URL ||
    'https://stg-customer-self-service.finn.auto',
  CUSTOMER_PORTAL_URL:
    getBranchAwareEnv('CUSTOMER_PORTAL_URL') || process.env.CUSTOMER_PORTAL_URL,
  FINN_AUTO_URL: process.env.FINN_AUTO_URL || '',
  UA_FE_ACTOR: process.env.UA_FE_ACTOR || 'ua_frontend',
  UA_FE_SSR_ACTOR: process.env.UA_FE_SSR_ACTOR || 'ua_frontend_ssr',
  FINN_MAIN_DOMAIN: process.env.FINN_MAIN_DOMAIN || 'https://www.finn.com',
  APP_PREFIX: process.env.APP_PREFIX || 'finnautoapp://',
  APP_PREVIEW_PREFIX: process.env.APP_PREVIEW_PREFIX || 'finnautoapppreview://',
  // ANALYTICS_WRITE_KEY will have different values between auto-ui and b2b-ui apps, as event tracking is separated
  // Default value is set to auto-ui key:
  ANALYTICS_WRITE_KEY:
    process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY ||
    'tQkptwFW7tocJCghWSiFGJarHixkbTHg',
  STRIPE: StripeTestKeySet,
  STRIPE_CUSTOMER_PORTAL_US_URL:
    process.env.STRIPE_CUSTOMER_PORTAL_US_URL ||
    'https://payments-us.finn.auto/p/login/test_cN2cNf23qgf86zK5kk',
  GMC_TAG: 'Xa9ZgPjLkRZp7GaPTMDzzki733VjXQ0eg8A2a51wJOQ',
  REVALIDATION_DEFAULT_TIMEOUT: 5 * 60,
  REVALIDATION_SHORT_TIMEOUT: 120,
  REVALIDATION_LONG_TIMEOUT: 15 * 60,
  GOOGLE_MAPS_GEOLOCATION_URL:
    'https://www.googleapis.com/geolocation/v1/geolocate',
  GOOGLE_MAPS_PLACES_API_DOMAIN_RESTRICTED:
    'AIzaSyDOkK0DSERRufvtGF5qzZDlGE-zLN0f2_E',
  GOOGLE_MAPS_SERVER_API_KEY: process.env.GOOGLE_MAPS_SERVER_API_KEY || '',
  EXTERNAL_VOUCHERS_API_KEY: process.env.EXTERNAL_VOUCHERS_API_KEY || '',
  APPS_FLYER_SMART_BANNER_KEY:
    process.env.APPS_FLYER_SMART_BANNER_KEY ||
    '75007f8c-c424-4fa5-bedb-97b945f34805',
  FINN_APP_SCHEME: process.env.FINN_APP_SCHEME || 'finnautoapp://',
  RECAPTCHA_E2E_SECRET: process.env.RECAPTCHA_E2E_SECRET,
  ZENDESK_JWT_SECRET: process.env.ZENDESK_JWT_SECRET || '',
  ZENDESK_APP_ID: process.env.ZENDESK_APP_ID || '',
  DECAGON_API_KEY: process.env.DECAGON_API_KEY || '',
  B2B_API_ENDPOINTS: {
    ADDONS: 'https://addons-api.b2b-staging.finn.com',
    CHECKOUT_V0: 'https://orders-api.b2b-staging.finn.com/v0',
    CHECKOUT_V1: 'https://orders-api.b2b-staging.finn.com/v1',
    SUBSCRIPTIONS: 'https://stg-b2b-subscriptions-api.finn.auto/v1',
    COMPANIES: 'https://companies.de.b2b.finn.com/api/v0',
    ORDERS: 'https://orders-api.b2b-staging.finn.com',
    PRODUCT: 'https://staging-product-api.finn.com/b2b',
    DELIVERY: 'https://api-delivery.finn.auto/v1',
    LEADS: 'https://api-lead.finn.auto/v0',
    INVOICES: 'https://invoice-b2b.finn.auto/v1',
    FINANCE_BILLING: 'https://api-billing.finance.finn.auto',
    FINANCE_INVOICING: 'https://api-invoicing.finance.finn.auto',
    OPS_CARS: 'https://api-ops-cars.finn.auto',
    OPS_SUBSCRIPTIONS: 'https://api-ops-subscriptions.finn.auto',
    OPS_APPRAISALS: 'https://api-ops-appraisals.finn.auto',
  },
};

const production: IEnvironment = {
  PRODUCT_API_URL: process.env.PRODUCT_API_URL || '',
  DATADOG_CLIENT_TOKEN:
    process.env.DATADOG_CLIENT_TOKEN || 'pub3ba7a11ecc7e9f18f2ef5dd41364a444',
  DATADOG_APPLICATION_ID:
    process.env.DATADOG_APPLICATION_ID ||
    'a3422b68-51d0-4ec3-a45c-2bb483409d25',
  DATADOG_RUM_SESSION_SAMPLE_RATE:
    process.env.DATADOG_RUM_SESSION_SAMPLE_RATE || '10',
  DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE:
    process.env.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE || '5',
  DD_ENV: process.env.DD_ENV || 'production',
  DD_VERSION: process.env.COMMIT_REF,
  GOOGLE_ANALYTICS_4_WEB: 'G-K7KRXNGPYV',
  DEPLOY_URL: process.env.NEXT_PUBLIC_DEPLOY_URL ?? 'https://www.finn.com',
  SUBSCRIPTION_API_URL: process.env.SUBSCRIPTION_API_URL || '',
  HUBSPOT_API_URL:
    process.env.HUBSPOT_API_URL || 'https://api.hubapi.com/crm/v3/objects',
  HUBSPOT_API_KEY: process.env.HUBSPOT_API_KEY || '',
  FINN_WEB_URL: 'https://www.finn.com',
  APP_STAGE: process.env.APP_STAGE || 'production',
  COSMIC_BUCKET_READ_KEY: process.env.COSMIC_BUCKET_READ_KEY || '',
  SUPPORT_EMAIL: 'support@finn.auto',
  HANDOVER_API_URL:
    process.env.HANDOVER_API_URL || 'https://api-car-handover.finn.auto',
  FLEET_API_URL: process.env.FLEET_API_URL || '',
  TAX_API_URL: process.env.TAX_API_URL || '',
  TAX_API_KEY: process.env.TAX_API_KEY || '',
  VOUCHER_API_URL:
    process.env.VOUCHER_API_URL || 'https://api-voucher.finn.auto',
  CHECKOUT_API_URL: process.env.CHECKOUT_API_URL || '',
  AUTH_API_URL: process.env.AUTH_API_URL || '',
  CLOUDINARY: {
    REMOTE_IMAGE_FETCH_URL: 'https://res.cloudinary.com/finn-auto/image/fetch/',
  },
  INTEGROMAT_CREATE_DEAL_URL:
    'https://hook.integromat.com/5f16mjfvbpy8d932knqyff88ji2duluz',
  INTEGROMAT_B2B_FORM_SUBMISSION_URL_DE:
    'https://hook.finn.integromat.cloud/2bs397kd9tqqq14k36cjw2mkxpiht4zg',
  INTEGROMAT_B2B_FORM_SUBMISSION_URL_US:
    'https://hook.finn.integromat.cloud/c9x5yv4lamrfdmfpx8ygfkfeonmgvqm8',
  INTEGROMAT_JOBAUTO_WHITEPAPER_FORM:
    'https://hook.finn.integromat.cloud/s5b7qbrkqjbruf6erjcxxvoyc5hhw7w7',
  INTEGROMAT_B2C_FORM_SUBMISSION_URL:
    process.env.INTEGROMAT_B2C_FORM_SUBMISSION_URL || '',
  INTEGROMAT_REFERRAL_VOUCHER_CODE:
    'https://hook.finn.integromat.cloud/d5qt21phj3zhg83bfr1a2e9gbehmxqzr',
  INTEGROMAT_RETENTION_VOUCHER_CODE:
    'https://hook.finn.integromat.cloud/d6cesdritxqlejdweo2w54sad8jfvhll',
  INTEGROMAT_JOBAUTO_FORM:
    'https://hook.finn.integromat.cloud/hwajx3lsy9qq30c0katp150g5g1sy0w9',
  INTEGROMAT_PARTNERSHIPS_FORM:
    'https://hook.finn.integromat.cloud/56gh7f6ldsy7ng77dtm3o5emvtfta135',
  LEVER: {
    API_URL: 'https://api.lever.co/v1',
    ACCESS_TOKEN: process.env.LEVER_ACCESS_TOKEN || '',
  },
  USERCENTRICS_ID: process.env.NEXT_PUBLIC_USERCENTRICS_ID || '',
  COSMIC_USE_S3: process.env.NEXT_PUBLIC_COSMIC_USE_S3 || '',
  TRACKING_TEST_EMAIL: process.env.NEXT_PUBLIC_TRACKING_TEST_EMAIL || '',
  SELF_SERVICE_API_URL: 'https://service-self.finn.auto',
  REVIEWS_API_URL: process.env.REVIEWS_API_URL || '',
  CUSTOMER_SELF_SERVICE_API_URL:
    process.env.CUSTOMER_SELF_SERVICE_API_URL ||
    'https://customer-self-service.finn.auto',
  CUSTOMER_PORTAL_URL: process.env.CUSTOMER_PORTAL_URL || '',
  APP_PREFIX: process.env.APP_PREFIX || 'finnautoapp://',
  FINN_AUTO_URL: process.env.FINN_AUTO_URL || '',
  UA_FE_ACTOR: process.env.UA_FE_ACTOR || 'ua_frontend',
  UA_FE_SSR_ACTOR: process.env.UA_FE_SSR_ACTOR || 'ua_frontend_ssr',
  FINN_MAIN_DOMAIN: process.env.FINN_MAIN_DOMAIN || 'https://www.finn.com',
  APP_PREVIEW_PREFIX: process.env.APP_PREVIEW_PREFIX || 'finnautoapppreview://',
  // ANALYTICS_WRITE_KEY will have different values between auto-ui and b2b-ui apps, as event tracking is separated
  // Default value is set to auto-ui key:
  ANALYTICS_WRITE_KEY:
    process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY ||
    'OKv4nrijjxsCGW8PQoextjRWe4VO4WGL',
  STRIPE: StripeLiveKeySet,
  STRIPE_CUSTOMER_PORTAL_US_URL:
    process.env.STRIPE_CUSTOMER_PORTAL_US_URL ||
    'https://payments-us.finn.auto/p/login/eVa5ok11u2zK2dibII',
  GMC_TAG: 'Xa9ZgPjLkRZp7GaPTMDzzki733VjXQ0eg8A2a51wJOQ',
  REVALIDATION_DEFAULT_TIMEOUT: 5 * 60,
  REVALIDATION_SHORT_TIMEOUT: 120,
  REVALIDATION_LONG_TIMEOUT: 15 * 60,
  GOOGLE_MAPS_GEOLOCATION_URL:
    'https://www.googleapis.com/geolocation/v1/geolocate',
  GOOGLE_MAPS_PLACES_API_DOMAIN_RESTRICTED:
    'AIzaSyAyXBwihs_C5jDvjj2zXXnGJjkQZl-vaBo',
  GOOGLE_MAPS_SERVER_API_KEY: process.env.GOOGLE_MAPS_SERVER_API_KEY || '',
  EXTERNAL_VOUCHERS_API_KEY: process.env.EXTERNAL_VOUCHERS_API_KEY || '',
  APPS_FLYER_SMART_BANNER_KEY:
    process.env.APPS_FLYER_SMART_BANNER_KEY ||
    '75007f8c-c424-4fa5-bedb-97b945f34805',
  FINN_APP_SCHEME: process.env.FINN_APP_SCHEME || 'finnautoapp://',
  ZENDESK_JWT_SECRET: process.env.ZENDESK_JWT_SECRET || '',
  ZENDESK_APP_ID: process.env.ZENDESK_APP_ID || '',
  DECAGON_API_KEY: process.env.DECAGON_API_KEY || '',
  B2B_API_ENDPOINTS: {
    ADDONS: 'https://addons-api.b2b.finn.com',
    CHECKOUT_V0: 'https://orders-api.b2b.finn.com/v0',
    CHECKOUT_V1: 'https://orders-api.b2b.finn.com/v1',
    SUBSCRIPTIONS: 'https://b2b-subscriptions-api.finn.auto/v1',
    COMPANIES: 'https://companies.de.b2b.finn.com/api/v0',
    ORDERS: 'https://orders-api.b2b.finn.com',
    PRODUCT: 'https://product-api.finn.com/b2b',
    DELIVERY: 'https://api-delivery.finn.auto/v1',
    LEADS: 'https://api-lead.finn.auto/v0',
    INVOICES: 'https://invoice-b2b.finn.auto/v1',
    FINANCE_BILLING: 'https://api-billing.finance.finn.auto',
    FINANCE_INVOICING: 'https://api-invoicing.finance.finn.auto',
    OPS_CARS: 'https://api-ops-cars.finn.auto',
    OPS_SUBSCRIPTIONS: 'https://api-ops-subscriptions.finn.auto',
    OPS_APPRAISALS: 'https://api-ops-appraisals.finn.auto',
  },
};

const environments: { [index: string]: IEnvironment } = {
  development,
  production,
  staging,
};

function getB2BTargetEnv() {
  if (process.env.IS_STAGING === 'yes') {
    return 'staging';
  }

  // For B2B, preview should use production unless explicitly set to staging
  if (process.env.APP_STAGE === 'preview') {
    return 'production';
  }

  return process.env.APP_STAGE;
}

function getDefaultTargetEnv() {
  return process.env.APP_STAGE === 'preview' || process.env.IS_STAGING === 'yes'
    ? 'staging'
    : process.env.APP_STAGE;
}

const targetEnv =
  process.env.APP_NAME === 'B2B' ? getB2BTargetEnv() : getDefaultTargetEnv();

const environment: IEnvironment =
  environments[targetEnv || 'production'] || production;

export default environment;
